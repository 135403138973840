.agencyShopPricing {
  .check {
    color: #1d7e51;
  }

  .togglePricing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;

    h1 {
      font-family: 'proxima-nova';
      font-weight: normal;
      font-size: 42px;
      margin: 0;
      color: #154255;
    }

    h2 {
      font-family: 'proxima-nova';
      font-weight: bold;
      font-size: 24px;
      color: #154255;
    }

    > div {
      border: 1px solid #ccc;
      background: #eee;
      display: flex;
      gap: 16px;
      margin: auto;
      padding: 4px 6px;
      border-radius: 12px;

      button {
        border: 1px solid #ccc;
        background: #fcfcfc;
        color: #222;
        padding: 12px;
        border-radius: 12px;
        font-size: 16px;
        cursor: pointer;

        &.active {
          border-color: #35A6D5;
        }

        span {
          background: -webkit-linear-gradient(-70deg, #6258D1 0%, #35A6D5 50%, #29B473 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-box-decoration-break: clone;

          i {
            font-size: 12px;
          }
        }
      }
    }

  }

  .plans {
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-gap: 20px;
    margin-top: 16px;
    justify-content: center;
    color: #000;

    .plan{
      margin-top: 26px;
    }
    .planRec {

    }

    .plan,
    .planRec{
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 8px;
      text-align: center;
      box-shadow: rgba(100, 100, 111, 0.6) 0px 7px 29px 0px;
      overflow: hidden;
      font-family: proxima-nova, sans-serif;

      .reccomended {
        border: 4px #3B357D solid;
        border-radius: 12px;
        background: #3B357D;
        color: #fff;

        > h4 {
          color: #fff;
          margin: 0;
          font-weight: 600;
          font-size: 12px;
          padding: 4px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        > div {
          background: #fff;
          color: #000;
          border-radius: 8px;
          overflow: hidden;
        }
      }

      .headerIndividual,
      .headerAgency {
        background: #2A85AA;
        padding: 16px;
        align-items: center;
        justify-content: center;
        display: flex;

        h3 {
          color: #fff;
          font-size: 24px;
          font-weight: 700;
          margin: 0;
        }
      }

      .headerIndividual {
        background: #fff;

        h3 {
          color: #000;
        }
      }

      .headerAgency {
        background: #fff;

        h3 {
          color: #000;
        }
      }

      .short {
        font-size: 14px;
        color: #777;
        margin: 0;
        padding: 16px 32px;
      }

      .select {
        padding: 0 16px;

        > button {
          cursor: pointer;
          border: 2px solid #aaa;
          color: #333;
          width: 100%;
          padding: 12px;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;

          &:hover {
            background: #F3FBF7;
            border: 2px solid #5E947B;
          }

          &.highlight {
            border: 2px solid #272354;
            color: #fff;
            background: #3B357D;

            &:hover {
              background: #4E46A7;
            }
          }
        }
      }

      .price {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 16px;

        > span {
          display: flex;
          font-size: 2.7rem;
          font-weight: 600;
          line-height: 1;
          color: #212529;

          .priceunit {
            font-size: 50%;
            align-self: flex-start;
          }
        }

        > label {
          display: block;
          text-transform: uppercase;
          color: #777;
          font-size: 0.7rem;
          line-height: 1;
          margin-bottom: 0;
        }
      }

      .features {
        border-top: 0;
        border-bottom: 0;
        padding: 0;
        text-align: center;

        .plus {
          text-align: left;
          flex-direction: row;
          justify-content: normal;
          align-items: center;
          padding: 8px;
          padding-top: 16px;

          i {
            font-size: 16px;
            line-height: 12px;
          }
        }

        .expandable {
          text-align: left;

          .title {
            display: flex;
            flex-direction: row;
            justify-content: normal;
            align-items: center;
            padding: 8px;
            cursor: pointer;
            font-weight: 600;
          }

          .content {
            padding: 8px 16px 8px 36px;
            color: #444;
          }
        }

        ul {
          padding: 0 8px 0 8px;
          padding: 0 0.5rem 0 0.5rem;
          margin-bottom: 0;

          li {
            border-bottom: 1px solid #ededed;
            line-height: 2.9;
            list-style: none;
            font-size: 0.9em;
          }
        }
      }

      .button {
        background: #1d7e51;
        border: 0;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        padding: 8px 16px;
        text-transform: uppercase;
      }
    }
  }

  .footer {
    font-family: proxima-nova, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
    font-size: 16px;
    color: #777;
    width: 800px;
    margin-right: auto;
    margin-left: auto;
    text-align: left;

    h2 {
      text-align: left;
      font-size: 24px;
      font-weight: 800;
    }

    .top {
      color: #35A6D5;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .lead {
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 300;
      margin-top: 0;
    }
  }
}

.section,
.sectionAgency {
  font-family: proxima-nova, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  color: #777;
  text-align: left;
  padding-bottom: 16px;

  > div {
    width: 800px;
    margin-right: auto;
    margin-left: auto;
  }

  h2 {
    font-family: proxima-nova, sans-serif;
    text-align: left;
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 16px;
  }

  .top {
    color: #35A6D5;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .lead {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin-top: 0;
  }

  li {
    margin-bottom: 8px;
  }
}

.sectionAgency {
  background: #3B357D;
  color: #E0DEF6;
  margin-top: 16px;

  strong {
    color: #eee;
  }

  .top {
    color: #fff;
  }

  h2 {
    color: #fff;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: static;
  width: 1280px;
  height: 1624.03px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -170px 0px;

  .innerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    position: static;
    width: 896px;
    height: 1494.03px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 50px 0px;

    .innerContainerLayer1 {

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;

      position: static;
      width: 896px;
      height: 1398.03px;
      margin-bottom: 42px;

      flex: none;
      order: 0;
      flex-grow: 0;

      .advantageDetailsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;

        position: static;
        width: 896px;
        height: 951.03px;

        flex: none;
        order: 1;
        flex-grow: 0;

        li {
          list-style-image: url(./liststylebullet.svg);

          font-family: 'proxima-nova';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 140%;
          margin-left: -10px;
          margin-bottom: 12px;

          color: #16346F;

          p {
            margin: 0;
            padding: 2px 0px 7px 7px;
            vertical-align: top;
            display: inline-block;
            margin-top: -7px;
          }

        }

        .speedUpContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 54px;

          position: static;
          width: 866px;
          height: 335.03px;

          flex: none;
          order: 1;
          flex-grow: 0;

          .speedUpDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;

            position: static;
            width: 457px;
            height: 324px;
            left: 409px;
            top: 5.51px;

            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 0px 59px;

            .speeduptext {
              position: static;
              width: 457px;
              height: 74px;
              margin-bottom: 10px;

              font-family: 'proxima-nova';
              font-style: normal;
              font-weight: bold;
              font-size: 32px;
              line-height: 115%;

              color: #16346F;
            }
          }

          .devFocusContainer {
            position: static;
            width: 350px;
            height: 335.03px;
            margin-top: 80px;
            margin-right: 70px;

            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }

        .subscriptionDetailsContainer {

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0px;

          position: static;
          width: 896px;
          height: 536px;
          left: 0px;
          margin-top: 34px;
          margin-bottom: -70px;

          .businessManContainer {
            position: static;
            width: 320.03px;
            height: 278.51px;
            left: 575.97px;
            top: 128.75px;

            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 0px 0px;
          }

          .subscriptionDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;

            position: static;
            width: 549px;
            height: 536px;
            left: 0px;
            top: 0px;

            flex: none;
            order: 0;
            flex-grow: 0;

            ul {
              width: 457px
            }

            .includedtext {
              font-family: 'proxima-nova';
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              line-height: 115%;
              margin: 0px;

              letter-spacing: 0.035em;
              text-transform: uppercase;

              color: #372DA3;
            }

            .wereheretext {
              width: 457px;
              font-family: 'proxima-nova';
              font-style: normal;
              font-weight: bold;
              font-size: 32px;
              line-height: 115%;
              margin-bottom: 10px;
              margin-top: 20px;

              color: #16346F;
            }
          }
        }
      }

      .myHealthilyAdvantageContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;

        position: static;
        width: 613px;
        height: 367px;
        left: 141.5px;
        margin-top: 150px;

        flex: none;
        order: 0;
        flex-grow: 0;

        .pricingContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0px;

          position: static;
          width: 613px;
          height: 170px;
          left: 0px;
          top: 197px;

          flex: none;
          order: 1;
          flex-grow: 0;

          .pricingCaveatContainer {
            position: static;
            width: 613px;
            height: 38px;
            left: 0px;
            top: 132px;

            flex: none;
            order: 1;
            flex-grow: 0;

            .monthlyfeetext {
              font-family: 'proxima-nova';
              font-style: italic;
              font-weight: normal;
              font-size: 16px;
              line-height: 120%;

              text-align: center;

              color: #16346F;
            }
          }

          .pricing {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 25px 65px;

            position: static;
            width: 265px;
            height: 108px;
            left: 174px;
            top: 0px;

            background: #EFEEFA;

            border: 3px solid #6258D1;
            border-radius: 4px;

            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 24px 0px;

            .pricingtext {
              font-family: 'proxima-nova';
              font-style: normal;
              font-weight: 500;
              font-size: 32px;
              line-height: 120%;

              display: flex;
              align-items: center;
              text-align: center;

              color: #3E33BA;
            }
          }
        }

        .advantageContainerSubLayer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;

          position: static;
          width: 613px;
          height: 157px;
          left: 0px;
          top: 0px;

          flex: none;
          order: 0;
          flex-grow: 0;

          .advantageDetailsContainer {
            position: static;
            width: 613px;
            height: 75px;
            left: 0px;
            top: 82px;

            flex: none;
            order: 1;
            flex-grow: 0;

            .turntext {
              font-family: 'proxima-nova';
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              line-height: 140%;
              text-align: center;
              width: 603px;
              margin-top: 16px;

              color: #16346F;
            }
          }

          .headerContainer {
            position: static;
            width: 613px;
            height: 58px;

            flex: none;
            order: 0;
            flex-grow: 0;

            .myhealthilytext {
              font-family: 'proxima-nova';
              font-style: normal;
              font-weight: 800;
              font-size: 48px;
              line-height: 120%;

              display: flex;
              align-items: center;
              text-align: center;

              color: #16346F;
            }

            .purplebox {
              position: relative;
              width: 241px;
              height: 20px;
              left: 373px;
              top: -24px;
              z-index: -1;

              background: #D0CDF1;
            }
          }
        }
      }
    }
  }
}
